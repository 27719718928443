import React from 'react';
import { Link } from 'react-router-dom';
import './ProjectCard.css';
import default_image1 from '../images/projects/default_images/1.jpg';
import default_image2 from '../images/projects/default_images/2.jpg';
import default_image3 from '../images/projects/default_images/3.jpg';

const ProjectCard = ({ projectData }) => {
  const { title, imagePath, keywords, description, link } = projectData;

  // Join keywords into a semicolon-separated string
  const keywordsString = keywords.join('; ');

  // Create an array of default images
  const defaultImages = [default_image1, default_image2, default_image3];

  // Choose a random default image from the array
  const randomImageIndex = Math.floor(Math.random() * defaultImages.length);
  const imageSrc = imagePath || defaultImages[randomImageIndex];

  return (
    <Link to={link} className="project-card unstyled-link">
      <div className="project-card-box">
        
        <div className="project-details">
          <h2 className="project-title">{title}</h2>
          <img src={imageSrc} alt={title} className="project-image" />
          <p className="project-keywords"><span style={{ fontWeight: 'bold' }}>Keywords: </span> {keywordsString}</p>
          <p className="project-description"><span style={{ fontWeight: 'bold' }}>Description: </span>{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
