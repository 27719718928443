import { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import NoPage from './pages/NoPage';
import About from './pages/About';
import Projects from './pages/Projects';
import "./App.css";

class App extends Component {
  render() {
      return (
        <div className="App">
          <BrowserRouter>
          <Navbar />
          <Routes>
            <Route index element={<About />} />
            <Route path="projects" element={<Projects />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </div>
      );
  }
}

export default App;
