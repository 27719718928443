// About.js
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./Page.css";
import "./About.css";
import personal_image from '../images/personal_image.jpg';
import linkedin_logo from '../images/linkedin_logo.svg';


class About extends Component {
  render() {
      return (
        <div className='Page About'>
            <div className='info-box'>
            <h2>About Me</h2>
            <p>Hi! I am Diogo Moura, I am a Software Engineer based in Portugal. I am deeply interested about technology, including machine learning, IoT and Cloud.</p>
            <img src={personal_image} alt="Personal" id="personal-image" />
            </div>
            <div className='info-box'>
            <h2>Contact Information</h2>
            <p>Please feel free to contact me on any of the below channels:</p>
            <ul>
              <li>
              <img src={linkedin_logo} alt="Linkedin Logo"/> Linkedin: <Link className="link" to="https://www.linkedin.com/in/diogo-moura-021168187/">https://www.linkedin.com/in/diogo-moura-021168187/</Link>
              </li>
            </ul>
          </div>
        </div>
      );
  }
};

export default About;
