// Home.js
import React, { Component } from 'react';
import "./Page.css";
import ProjectsList from '../components/ProjectsList';

class Projects extends Component{
  render() {
    return (
      <div className="Page">
        <h2>Projects</h2>
        <p>Here I display projects I have developed along the years. Click on any project to open it.</p>
        <ProjectsList />
      </div>
    );
}
};


export default Projects;
