import ProjectCard from "./ProjectCard";
import "./ProjectCard.css";

const projects = [
  {
    title: 'Cloud Radar Scanner',
    //imagePath: '/images/project1.jpg',
    keywords: ['AWS', 'Cloud', 'Java', 'EC2', 'DynamoDB'],
    link: 'https://github.com/esyker/CNV_Raddar_Scanner',
    description: "Developed a web-server, a load-balancer and an auto-scaling group exclusively using AWS EC2 and DynamoDB."
  },
  {
    title: 'Cloud SafeHome IOT',
    keywords: ['Kong API Gateway', 'Kafka','AWS Lambda', 'Microservices', 'IoT' ,'Camunda', 'BPM'],
    link: 'https://github.com/esyker/SafeHomeIoT',
    description: "Implemented a Microservices architecture using an API gateway and AWS Lambda. A Kafka Cluster is used to process events from IoT devices, which are generated using a simulator."
  },
  {
    title: 'Parallel and Distributed KNN Algorithm',
    keywords: ['C++', 'OpenMP','OpenMPI','KNN'],
    link: 'https://github.com/esyker/KNN_BallAlg',
    description: "Serial, Parallel and Distributed versions of the Ball algorithm for finding KNN, using OpenMP and OpenMPI."
  },
  {
    title: 'BitChallenge',
    keywords: ['Arduino', 'C++'],
    link: 'https://github.com/esyker/BitChallenge',
    description: "Developed a gaming console using Arduino, for an University Constent, for which I was awarded the 1st prize."
  },
  {
    title: 'AI Vision&Language Models Fine-tuning',
    keywords: ['Python', 'HuggingFace', 'Pytorch'],
    link: 'https://github.com/esyker/Fine-tuning-Vision-Language-Models/',
    description: "Fine-tuning of Vision&Language models for visual spatial reasoning using image+text."
  },
];

function ProjectsList() {
  return (
    <div className="projects-list">
      {projects.map((project) => (
        <ProjectCard key={project.title} projectData={project} />
      ))}
    </div>
  );
}

export default ProjectsList;
